<template>
  <v-container>
    <!-- Heading Text -->
    <h2 class="font-weight-bold mb-12 black--text">
      <v-icon class="back-button" @click="$router.go(-1)">
        {{ icons.mdiChevronLeft }}
      </v-icon>
      Create Landing Page
    </h2>

    <!-- Toolbar -->
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="keywords"
          single-line
          hide-details
          dense
          outlined
          class="shrink"
        >
        </v-text-field>
      </v-col>

      <v-spacer />

      <v-col cols="6" sm="4" md="3">
        <!-- Menu 1 -->
        <v-select
          v-model="landingPageGoalsSelect"
          :items="landingPageGoalsOptions"
          label="Landing page goals"
          outlined
          dense
        ></v-select>
      </v-col>

      <v-col cols="6" sm="4" md="2">
        <!-- Menu 1 -->
        <v-select v-model="industriesSelect" :items="industriesOptions" label="Industries" outlined dense></v-select>
      </v-col>
    </v-row>

    <!-- Grid -->
    <v-row>
      <v-col v-for="template in landingPageTemplates" :key="template.id" cols="12" sm="6" md="6" lg="4" xl="3">
        <v-hover v-slot="{ hover }">
          <v-card class="card mx-auto rounded-lg" min-width="330" max-width="330">
            <v-container class="px-6 pt-6 img-container rounded-lg">
              <v-img
                :src="template.imageUrl + `${template.id + 213}/300/200`"
                height="160px"
                class="rounded-t-lg"
              ></v-img>

              <!-- Hover state -->
              <div v-if="hover" class="card-overlay rounded-lg">
                <v-btn
                  color="#1F979E"
                  class="text-capitalize me-6 white--text"
                  @click="$router.push('/landing-page/editor')"
                >
                  Edit
                </v-btn>
                <v-btn class="text-capitalize" @click="$router.push('/landing-page/preview')"> View </v-btn>
              </div>
            </v-container>

            <v-card-title class="px-0 py-6">
              <span class="card-title">{{ template.title }}</span>
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiChevronLeft, mdiMagnify, mdiMenuDown } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const search = ref('')

    return {
      icons: { mdiChevronLeft, mdiMagnify, mdiMenuDown },
      search,
    }
  },
  data() {
    return {
      landingPageGoalsSelect: '',
      landingPageGoalsOptions: ['Sukses', 'Makmur', 'Jaya'],
      industriesSelect: '',
      industriesOptions: ['Tech', 'Fintech', 'Commerce'],

      landingPageTemplates: [
        { id: 1, imageUrl: 'https://picsum.photos/id/', title: 'Blank Template' },
        { id: 2, imageUrl: 'https://picsum.photos/id/', title: 'Webinar Landing Page' },
        { id: 3, imageUrl: 'https://picsum.photos/id/', title: 'Personal Blog' },
        { id: 4, imageUrl: 'https://picsum.photos/id/', title: 'Tech Company' },
        { id: 5, imageUrl: 'https://picsum.photos/id/', title: 'People Management' },
        { id: 6, imageUrl: 'https://picsum.photos/id/', title: 'AI Company' },
        { id: 7, imageUrl: 'https://picsum.photos/id/', title: 'Game Company' },
        { id: 8, imageUrl: 'https://picsum.photos/id/', title: 'Chat Application' },
        { id: 9, imageUrl: 'https://picsum.photos/id/', title: 'SuperWA' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.back-button {
  cursor: pointer;
}

.card.v-sheet.v-card {
  border: 0px solid white !important;
  cursor: pointer;
}

.img-container {
  background-color: #f1f1f1;
  position: relative;
}

.card-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.card-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
